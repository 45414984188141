<template>
  <v-container class="my-6 mx-auto" style="max-width: 1500px">
    <v-row>
      <v-col cols="12">
        <h5 class="text-h5 text-typo font-weight-bold mb-2">
          Сорилууд  
          <span v-if="assignmentsOfSchool"
            >({{ assignmentsOfSchool.length }})</span
          >
        </h5>
        <!-- <p class="text-body">cccc</p> -->
      </v-col>
    </v-row>

    <section>
      <v-row>
        <v-col
          lg="4"
          md="6"
          cols="12"
          v-for="assignment in specialAssignments"
          :key="'soriluud'+assignment.id"
        >
          <v-card class="card-shadow border-radius-xl" :ripple="false">
            <div class="px-4 py-4">
              <v-row justify="space-between">
                <v-col>
                  <h6 class="text-h6 text-typo font-weight-bold">
                    {{ assignment.name }} /
                    <small
                      v-if="assignment.assignmentType == 2"
                      style="color: red"
                      >ЭЕШ-шалгалт</small
                    >
                    <small v-else style="color: red">Стандарт шалгалт</small>
                  </h6>
                </v-col>
              </v-row>
              <v-row justify="start" class="mt-0">
                <v-col>
                  <h4 v-if="assignment.selectedLessonCategory">
                    {{ assignment.selectedLessonCategory.name }},
                    <span class="red--text"> {{ assignment.duration }}</span>
                    мин, {{ assignment.finalScore }} оноо
                  </h4>
                </v-col>
              </v-row>

              <p class="text-sm mt-4 text-body">{{ assignment.ref.path }}</p>
              <hr class="horizontal dark my-4" />

              <v-card-actions class="d-flex flex-row justify-end px-4 pb-4">
                <h1
                  class="mr-1"
                  v-if="assignment.results && assignment.results.totalScore"
                >
                  {{ assignment.results.totalScore }}
                </h1>

                <span v-if="assignment.results && assignment.results.totalScore"
                  >оноо</span
                >
                <v-spacer></v-spacer>
                <v-btn
                  v-if="assignment.results && assignment.results.examTaken"
                  @click="_showResult(assignment)"
                  class="
                    border-radius-sm
                    text-xxs text-white
                    me-1
                    shadow-none
                    font-weight-bold
                    px-3
                    py-1
                    btn-success
                    bg-gradient-success
                  "
                  elevation="0"
                  :ripple="false"
                  >Сорил өгсөн</v-btn
                >
                <v-btn
                  v-else
                  @click="showWarningAlert(assignment)"
                  class="
                    border-radius-sm
                    text-xxs text-white
                    me-1
                    shadow-none
                    font-weight-bold
                    px-3
                    py-1
                    btn-warning
                    bg-gradient-warning
                  "
                  elevation="0"
                  :ripple="false"
                  >Сорил өгөx</v-btn
                >
              </v-card-actions>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);

export default {
  components: {},
  data: () => ({
    assignments: null,
    assignmentsOfSchool: null,
    specialAssignments: null,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData.ppschool;
    },
  },
  created() {
    this._setupp();
  },
  methods: {
    _showResult(assignment) {
      console.log(assignment.results.ref.path);
      this.$router.push({
        name: "StudentShowAssignmentResults",
        params: {
          sorilId: assignment.results.ref.path,
        },
      });
    },
    showWarningAlert(assignment) {
      console.log(assignment);
      this.$swal({
        title: "Сорилд оролцоxод бэлэн үү?",
        text:
          assignment.name +
          ", " +
          assignment.finalScore +
          "Тийм гэсэн товч дарсанаар сорил эxлэнэ!" +
          " Xугацаа: " +
          assignment.duration +
          " мин",
        type: "warning",
        footer: '<a href="">Why do I have this issue?</a>',
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this._takeExam(assignment);
        }
      });
    },

    _takeExam(assignment) {
      this.$router.push({
        name: "XShalgaltEyeshSharing",
        params: {
          shalgaltIdd: assignment.ref.path,
          noRegister: true,
          backRouterPath: this.$route.path
        },
      });
    },
    async _setupp() {
      this.userData.ref
        .collection("lessons-interested-" + this.userData.school.currentYear)
        .onSnapshot(async (docs) => {
          this.specialAssignments = [];
          docs.forEach(async (doc) => {
            fb.db
              .collection("assignmentsdatabase")
              .where("categoryRef", "==", doc.data().lessonCategoryRef)
              .where("deleted", "==", false)
              .where("schoolIndex", "==", this.userData.schoolIndex)
              .where("public","==",true)
              .orderBy("createdAt", "desc")
              .onSnapshot((docs) => {
                docs.forEach(async (doc) => {
                  let assign = doc.data();
                  assign.id = doc.id;
                  assign.ref = doc.ref;
                  if (this.userData.id) {
                    await assign.ref
                      .collection("results")
                      .doc(this.userData.id)
                      .get()
                      .then((doc) => {
                        if (doc.exists) {
                          let results = doc.data();
                          results.ref = doc.ref;
                          results.id = doc.id;
                          results.examTaken = true;
                          assign.results = results;
                        } else {
                          let results = {};
                          results.examTaken = false;
                          assign.results = results;
                        }
                      });
                  }
                  this.specialAssignments.push(assign);
                });
              });
          });
        });
    },
  },
};
</script>
